<script>
import {mapActions, mapGetters} from "vuex";
import InputEmail from "@/components/Inputs/base/InputEmail";
import InputCode from "@/components/Inputs/base/InputCode";

export default {
  name: "Auth",
  components: {InputCode, InputEmail},
  data: function() {
    return {
      isValid: false,
      loading: false,
      codeInput: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (to.query.uuid) {
        await vm.$router.push({name: "Finished"})
        // !! comment to close the questionnaire !!
        // vm.$store.commit("User/UUID", to.query.uuid)
        // try {
        //   await vm.$store.dispatch("User/uuid")
        //   await vm.$router.push({name: "Questionnaire"})
        // } catch (e) {
        //   await vm.$router.push({name: "Expired"})
        // }
      }
    })
  },
  computed: {
    ...mapGetters("User", {
      credentialsCode: "getCredentialsCode",
      credentialsEmail: "getCredentialsEmail"
    }),
    codeHint() {
      return 'Код подтверждения отправлен на указанный адрес электронной почты'
        + (this.credentialsEmail ? ` <strong>${this.credentialsEmail}</strong>` : '')
        + '. Введите код в поле ниже.'
    }
  },
  methods: {
    ...mapActions("Task", {
      taskSecure: "taskSecure",
    }),
    ...mapActions("User", {
      code: "code",
      login: "login",
      init: "init",
    }),
    back() {
      if (this.codeInput) {
        this.codeInput = false
      } else this.$router.push("/")
    },
    async forward() {
      if (!this.isValid) return
      this.loading = true
      try {
        await this.login()
        await this.init()
        if (this.nextTaskId)
          await this.taskSecure()
        await this.$router.push("/")
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async recaptcha() {
      if (!this.isValid) return
      this.loading = true
      try {
        await this.$recaptchaLoaded()
        this.$store.commit("User/CAPTCHA", await this.$recaptcha('login'))
        await this.code()
        this.codeInput = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <div>
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          md="8"
          lg="6"
          xl="4"
        >
          <p class="description">
            Авторизация
          </p>
          <p
            v-if="codeInput"
            class="name"
            v-html="codeHint"
          />
          <v-form
            v-model="isValid"
            @submit.prevent
          >
            <InputEmail
              v-if="!codeInput"
              @enter="recaptcha"
            />
            <p
              v-if="!codeInput"
              class="name"
            >
              {{ $t("pages.auth.terms-text") }}
              <a
                href="#"
                target="_blank"
              >
                {{ $t("pages.auth.terms-link") }}
              </a>
            </p>
            <InputCode
              v-else
              @enter="forward"
            />
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <div class="toolbar">
      <v-btn
        depressed
        tile
        large
        @click="back"
      >
        Назад
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="codeInput"
        depressed
        tile
        large
        :disabled="!isValid || loading"
        color="primary"
        @click="forward"
      >
        Войти
      </v-btn>
      <v-btn
        v-else
        :disabled="!isValid || loading"
        color="primary"
        large
        tile
        depressed
        @click="recaptcha"
      >
        Получить код
      </v-btn>
    </div>
  </div>
</template>